import React, { useState, useContext, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import {useWindowScroll} from 'react-use'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, type } from '../../styles/global'
import { MenuContext } from '../Menu/MenuProvider'

export const headerHeight = 102
export const phoneHeaderHeight = 90

const Header = (props) => {
	const { bgColour, fixed } = props
	const [scrolled, setScrolled] = useState(false)
	const menu = useContext(MenuContext)
	const {x, y} = useWindowScroll()

	useEffect(() => {
		if (y > 0) {
			setScrolled(true)
		} else {
			setScrolled(false)
		}
	}, [y])

	return (
		<Wrapper bgColour={bgColour} scrolled={scrolled} fixed={fixed} >
			<Container>
				<HomeLink
					onClick={() => {
						navigate('/')
					}}
				>
					<span className="desktop">
						Not Yet It's Difficult
					</span>
					<span className="phone">
						NYID
					</span>
				</HomeLink>

				<MenuToggle
					onClick={() => menu.toggleActive()}
				>
					Menu
				</MenuToggle>
			</Container>
		</Wrapper>
	)
}

const HomeLink = styled.div``
const MenuToggle = styled.div``

// Layout

const Wrapper = styled.div`
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;

	${props => {
		if (props.fixed)
			return css`
				position: fixed;
			`
	}}

	${props => {
		if (props.scrolled)
			return css`
				background-color: white;
			`
	}}

	${props => {
		if (props.bgColour && props.scrolled)
			return css`
				background: ${props.bgColour};
			`
	}}
`

const Container = styled.div`
	${container}
	${padding}
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${headerHeight}px;

	${media.phone`
		height: ${phoneHeaderHeight}px;
	`}

	${HomeLink}, ${MenuToggle} {
		cursor: pointer;
		${type.heading1}
		line-height: 1;
	}
`

export default Header
