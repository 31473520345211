import { 
    BlockBodyText,
    BlockHeading,
    BlockIntroText,
    BlockDoubleImage,
    BlockSingleImage,
    BlockTableList,
    BlockPeopleGrid,
    BlockInfoBox,
    BlockLargeText,
    BlockFileDownload,
    BlockSectionDivider
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'body_text': BlockBodyText,
        'heading': BlockHeading,
        'intro_text': BlockIntroText,
        'single_image': BlockSingleImage,
        'double_image': BlockDoubleImage,
        'table_list': BlockTableList,
        'people_grid': BlockPeopleGrid,
        'info_box': BlockInfoBox,
        'large_text_with_label': BlockLargeText,
        'file_download': BlockFileDownload,
        'section_divider': BlockSectionDivider
    }
    
    return blocks[layout]
}


