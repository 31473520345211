import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockIntroText = (props) => {
	const { intro_text } = props;
	if (!intro_text.display || !intro_text.text) return false
        
	return (
		<Wrapper>
			<Text
				dangerouslySetInnerHTML={{__html: intro_text.text}}  
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Text = styled.div`
    width: 960px;
	max-width: 100%;
    padding-top: 56px;

	${media.phone`
		padding-top: 29px;
	`}

	&, * {
		${type.heading1}
	}

	> *:not(:first-child) {
		margin-top: 1em;
	}
`

BlockIntroText.wrapper = css`
	margin-bottom: 64px;
`

export default BlockIntroText
