import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Headroom from 'react-headroom'
import { useWindowScroll } from 'react-use'
import { animateScroll as scroll } from 'react-scroll'

import { media, isClient } from '../../styles/utils'
import { getGlobalData } from '../../utils'
import { container, padding, hoverState, type } from '../../styles/global'

import instagram from '../../assets/icons/instagram.svg'
import twitter from '../../assets/icons/twitter.svg'
import linkedin from '../../assets/icons/linkedin.svg'
import vimeo from '../../assets/icons/vimeo.svg'

export const footerHeight = 102

const Footer = (props) => {
    const { bgColour, visible } = props
    const { y } = useWindowScroll()
    const [ scrollBottom, setScrollBottom ] = useState(false)
    const { global: globalData } = getGlobalData()
    // console.log(globalData)

    useEffect(() => {
        if (isClient()) {
            const offset = 100
            if ((y + window.innerHeight) >= (document.documentElement.scrollHeight - offset)) {
                setScrollBottom(true)
            } else {
                setScrollBottom(false)
            }
        }
    }, [y])

    const renderSocial = className => {
        return (
            <SocialLinks className={className && className}>
                {globalData.twitter_display && globalData.twitter_url && (
                    <ExternalLink
                        href={globalData.twitter_url}
                        target='_blank'
                    >
                        <Icon src={twitter} />
                    </ExternalLink>
                )}
                {globalData.instagram_display && globalData.instagram_url && (
                    <ExternalLink
                        href={globalData.instagram_url}
                        target='_blank'
                    >
                        <Icon src={instagram} />
                    </ExternalLink>
                )}
                {globalData.use_linkedin && globalData.linkedin_url && (
                    <ExternalLink
                        href={globalData.linkedin_url}
                        target='_blank'
                    >
                        <Icon src={linkedin} />
                    </ExternalLink>
                )}
                {globalData.vimeo_display && globalData.vimeo_url && (
                    <ExternalLink
                        href={globalData.vimeo_url}
                        target='_blank'
                    >
                        <Icon src={vimeo} />
                    </ExternalLink>
                )}
            </SocialLinks>
        )
    }

    return (
        <Wrapper
            scrollBottom={scrollBottom}
            bgColour={bgColour}
            visible={visible}
        >
            <Headroom disableInlineStyles={true}>
                <Container className={'phone'}>
                    {renderSocial()}
                </Container>
                <Container>
                    <Left>
                        <ScrollToTop
                            onClick={() => {
                                scroll.scrollToTop({
                                    smooth: true,
                                    duration: 500
                                })
                            }}
                        >
                            <span className="phone">Top &uarr;</span>
                            <span className="desktop">Back to top</span>
                        </ScrollToTop>
                    </Left>

                    {renderSocial('desktop')}
                    
                    <Right>
                        {globalData && globalData.footer_contact_email && (
                            <Email>
                                <ExternalLink
                                    href={`mailto:${globalData.footer_contact_email}`}
                                    target='_blank'
                                >
                                    {globalData.footer_contact_email}
                                </ExternalLink>
                            </Email>
                        )}
                    </Right>
                </Container>
            </Headroom>
        </Wrapper>
    )
}

// Shared

const ExternalLink = styled.a``
const HomeLink = styled.div``
const MenuToggle = styled.div``
const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    .headroom {
        z-index: 10;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        transition: transform 200ms ease-in-out, opacity 300ms ease-in-out;
        width: 100%;
        background-color: white;
        opacity: 0;

        ${media.phone`
            position: static;
            transition: none!important;
            transform: none!important;
        `}
    }
    .headroom--unfixed {
        transform: translateY(0%);
    }
    .headroom--unpinned {
        transform: translateY(100%);
    }
    .headroom--pinned {
        transform: translateY(0%);
    }

    ${props => {
        if (props.visible)
            return css`
                .headroom {
                    opacity: 1;
                }
            `
    }}

    ${props => {
        if (props.scrollBottom)
            return css`
                .headroom {
                    transform: translateY(0%)!important;
                }
            `
    }}

	${props => {
		if (props.bgColour)
			return css`
                &, .headroom {
                    background: ${props.bgColour};
                }
			`
	}}
`

const Left = styled.div``
const Right = styled.div``

const Container = styled.div`
    ${container}
    ${padding}
	display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 26px;
    padding-bottom: 31px;

    ${media.phone`
        padding-top: 0;
        padding-bottom: 0;

        // social links container
        &:first-child {
            padding-top: 55px;
            padding-bottom: 23px;
        }

        // bottom container

        &:nth-child(2) {
            padding-top: 31px;
            padding-bottom: 31px;
        }
    `}

    ${Left}, ${Right} {
        flex: 50%;
    }

    ${Right} {
        text-align: right;
    }
`

const ScrollToTop = styled.div`
    cursor: pointer;

    span {
        ${type.heading1}
    }
`

const SocialLinks = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${ExternalLink} {
        margin: 0 16px;
    }
`

const Email = styled.div`
    ${ExternalLink} {
        ${type.heading1}
    }
`

export default Footer
