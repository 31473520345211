import React, { useState, Component, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Form, { useForm } from 'react-dynamic-form'
import { email } from '../../validators'
import { postData } from '../../services/api'

import { media } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'

const MailingList = (props) => {
    const [form, { fieldState, formSubmit, setError }] = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)

    useEffect(() => {
        if (fieldState?.email?.value?.length > 0) {
            setWasFocused(true)
        }
        if (wasFocused) {
            setCanSubmit(fieldState?.email?.valid)

            if (!fieldState?.email?.valid) {
                setError('Please enter a valid email address')
            } else {
                setError(false)
            }
        }
    }, [fieldState?.email])

    const handleSubmit = (fields, reset) => {
        const data = {
            email: fields.email.value,
        }

        console.log(data)

        setSubmitting(true)

        postData('/more/campaign-monitor-subscribe', data)
            .then(res => {
                console.log(res)
                if (res.success) {
                    setFormComplete(true)
                    setSubmitting(false)
                } else {
                    setError(res.errors[0])
                    setSubmitting(false)
                }
            })
            .catch(error => {
                setError(error)
                setSubmitting(false)
            })
    }

    return (
        <Wrapper>
            {formComplete && (
                <Description
                    className={'form-thanks'}
                    dangerouslySetInnerHTML={{
                        __html: `Thankyou`,
                    }}
                ></Description>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
                submitting={submitting}
            >
                <Form
                    form={form}
                    data={signupFields()}
                    renderSubmit={false}
                    renderInput={<Input />}
                    onSubmit={handleSubmit}
                />

                {form?.error && (
                    <Error
                        className={'form-error'}
                        dangerouslySetInnerHTML={{
                            __html: form?.error,
                        }}
                    />
                )}

                <Submit
                    canSubmit={canSubmit}
                    onClick={() => canSubmit ? formSubmit() : setError('Please enter a valid email address')}
                >
                    {canSubmit ? 'Submit' : 'Subscribe'}
                </Submit>
            </FormWrapper>
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Error = styled.div``

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &,
    * {
        font-size: 35px;
        line-height: 1.29;
    }

    .form-thanks {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    ${Error} {
        margin-top: 16px;
        font-size: 14px;
        line-height: 1;
        color: #520606!important;
    }
`

const Submit = styled.div`
    ${props => {
        if (props.canSubmit)
            return css`
                cursor: pointer;
            `
    }}
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${(props) => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &,
                * {
                    pointer-events: none;
                }
            `
    }}

    ${(props) => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &,
                * {
                    pointer-events: none;
                }
            `
    }}

    &,
    > div {
        width: 100%;
    }

    input {
        border: none;
        border-bottom: 5px solid black;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 18px;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            color: black;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    ${Submit} {
        margin-top: 10px;
    }
`

const Input = (props) => {
    const { errorMessage } = props

    const onChange = (e) => {
        const { onChangeValue, validator } = props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    const renderInput = () => {
        const { type } = props

        return (
            <input
                value={props.value}
                onChange={onChange}
                placeholder={props?.placeholder}
            />
        )
    }

    return renderInput()
}


export const signupFields = () => {
    const fields = [
        {
            type: 'email',
            name: 'email',
            validator: email,
            errorMessage: 'Please enter a valid email address',
            placeholder: 'Mailing list',
        },
    ]

    return fields
}

export default MailingList
