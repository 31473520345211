import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockLargeText = (props) => {
    const { large_text_with_label } = props
    if (!large_text_with_label.display || !large_text_with_label.text)
        return false

    return (
        <Wrapper>
            <Text>
                <Label
                    dangerouslySetInnerHTML={{
                        __html: large_text_with_label?.label,
                    }}
                />
                <Value
                    dangerouslySetInnerHTML={{
                        __html: large_text_with_label?.text,
                    }}
                />
            </Text>
        </Wrapper>
    )
}

const Label = styled.div``
const Value = styled.div``
const Text = styled.div``

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${Text} {
        display: flex;
        width: 1028px;
        max-width: 100%;
        margin: 0 auto;

        ${media.phone`
            display: block;
        `}

        ${Label} {
            min-width: 346px;
            max-width: 100%;
            padding-right: 32px;

            ${media.phone`
                padding-right: 0;
            `}
        }

        ${Value} {
            > *:not(:first-child) {
                margin-top: 1em;
            }
        }

        &,
        * {
            ${type.heading1}
        }
    }
`

BlockLargeText.wrapper = css`
    margin-bottom: 162px;

    ${media.phone`
        margin-bottom: 115px;
    `}
`

export default BlockLargeText
