export const required = (value) => value && value !== "" && value?.length >= 1;

export const requiredBool = (value) => {return value};

export const email = value => {
    const emailTest = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailTest.test(value)
}

export const phone = value => {
    if (!value) return false
    const phoneTest = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    return phoneTest.test(value)
}

export const number = value => {
    const numberTest = /^[0-9]+$/;
    return numberTest.test(value)
}

// min 8 letter password, with at least a symbol, upper and lower case letters and a number
export const password = value => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(value);
}

export const date = dates => {
    const { startDate, endDate } = dates || {}
    if (startDate) return true
}
