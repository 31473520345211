import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useMeasure } from 'react-use'
import { motion } from 'framer-motion'
import times from "lodash/times"

import { media, useBreakpoint } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'

const Ticker = (props) => {
    const { text, className, isPressPage, tickerWidth: forceTickerWidth, animate: forceAnimate } = props
    if (!text) return false
    const [tickerRef, { width: tickerWidth }] = useMeasure()
    const [textRef, { width: textWidth }] = useMeasure()
    const [canAnimate, setCanAnimate] = useState(false)
    const [duration, setDuration] = useState(false)
    const [loopChildren, setLoopChildren] = useState(3)
    const [loopDuration, setLoopDuration] = useState(false)
    const [loopDelay, setLoopDelay] = useState(false)
    const [animate, setAnimate] = useState(false)
    const isPhone = useBreakpoint('phone')

    useEffect(() => {
        if (textWidth > tickerWidth && !isPhone) {
            setCanAnimate(true)
        } else if (canAnimate) {
            setCanAnimate(false)
        }
    }, [tickerWidth, textWidth])

    useEffect(() => {
        setAnimate(forceAnimate)
    }, [forceAnimate])

    useEffect(() => {
        if (canAnimate && textWidth > 0) {
            const calcDuration = textWidth / 75
            if (duration !== calcDuration) {
                setDuration(calcDuration)
            }
        }
        if (canAnimate && textWidth > 0 && tickerWidth > 0) {
            const calcLoopDuration = (textWidth+tickerWidth) / 75
            if (loopDuration !== calcLoopDuration) {
                setLoopDuration(calcLoopDuration)
            }
        }

        if (tickerWidth > 0) {
            setLoopDelay((tickerWidth/2)/75)
        }
    }, [canAnimate, textWidth, tickerWidth])

    const textVariants = {
        reveal: {
            x: -textWidth,
            transition: {
                ease: 'linear',
                duration: duration,
            },
        },
        hide: {
            x: 0,
            transition: {
                duration: 0,
            },
        },
    }

    const resolveLoopDelay = (index) => {
        if (index === 1) {
            return duration-loopDelay
        } else {
            return (duration-loopDelay) + ((loopDuration-loopDelay)*(index-1))
        }
    }

    const loopTransition = {
        loop: Infinity,
        ease: 'linear',
        duration: loopDuration,
    }

    const resolveLoopVariants = () => {
        let variants = {}

        variants['hide'] = {
            x: 0,
            transition: {
                duration: 0,
            },
        }

        for (let index = 1; index <= loopChildren; index++) {
            variants[`loop${index}`] = {
                x: -textWidth-tickerWidth,
                transition: {
                    delay: resolveLoopDelay(index),
                    repeatDelay: ((loopChildren-1)*loopDuration)-((loopChildren-1)*loopDelay)-loopDelay,
                    ...loopTransition
                }
            }
        }

        return variants
    }

    const loopVariants = canAnimate ? resolveLoopVariants(loopChildren) : false

    return (
        <Wrapper
            className={className && className}
            canAnimate={canAnimate}
            animate={animate}
            isPressPage={isPressPage}
            onMouseEnter={() => canAnimate && setAnimate(true)}
            onMouseLeave={() => {
                setAnimate(false)
            }}
            style={{
                width: forceTickerWidth && forceTickerWidth,
            }}
        >
            <TickerText
                style={{
                    position: 'relative'
                }}
                ref={tickerRef}
                showEllipsis={canAnimate && !animate}
            >
                <Text
                    ref={textRef}
                    initial={'hide'}
                    animate={animate ? 'reveal' : 'hide'}
                    variants={textVariants}
                    dangerouslySetInnerHTML={{ __html: text }}
                />

                {animate && times(loopChildren, Number).map((id) => (
                    <Text
                        style={{
                            position: 'absolute',
                            left: '100%'
                        }}
                        animate={animate ? `loop${id+1}` : 'hide'}
                        variants={loopVariants}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                ))}
            </TickerText>
        </Wrapper>
    )
}

const TickerText = styled.div``
const Text = styled(motion.div)``
const Ellipsis = styled.div``

const Wrapper = styled.div`
    position: relative;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    ${media.phone`
        white-space: initial;
    `}

    ${props => {
        if (props.canAnimate && !props.animate)
            return css`
                &::after {
                    content: '...';
                    background-color: white;
                    z-index: 1;
                    display: flex;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            `
    }}

    ${props => {
        if (props.isPressPage)
            return css`
                &::after {
                    background-color: #DDDBC3;
                }
            `
    }}

    ${TickerText} {
        * {
            display: inline-block;
        }
    }
`

export default Ticker
