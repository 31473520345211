import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Ticker, Link } from '../'
import { media } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'

const TableList = (props) => {
	const { columns_number, headers, display_bottom_line, isPressPage } = props

    let headings = []
	for (let index = 0; index < columns_number; index++) {
		const header = props[`header_${index+1}`]
		if (header) {
			headings.push(header)
		}
	}

    const renderListHeadings = () => {
        return headings.map((item, i) => {
            return (
                <Col
                    key={i}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item,
                        }}
                    />
                </Col>
            )
        })
    }

    const renderListRow = (columnCount, row) => {
        let columns = []
        for (let index = 0; index < columnCount; index++) {
            const column = row[`column_${index+1}`]
            columns.push(column)
        }

        return columns.map((item, i) => {
            return (
                <Col
					key={i}
                >
                    <Ticker
                        isPressPage={isPressPage}
                        text={item}
                    />
                </Col>
            )
        })
    }

    const renderListRows = (columnCount, rows) => {
        return rows.map((item, i) => {
            return (
                <Row key={i} className={i===0 && 'first-row'}>
                    {renderListRow(columnCount, item)}
                </Row>
            )
        })
    } 

    const renderListing = (items) => {
        if (!items) return

        const listItems = items.map((item, i) => {
            if (item.heading) {
                return (
                    <List
                        key={i}
						className={'table-list'}
                    >
                        <Row className={'listing-heading'}>
                            {item?.use_heading_link && (
                                <Link
                                    className={'custom-link'}
                                    to={item?.heading_link}
                                >
                                    {item?.heading}
                                </Link>   
                            )}
                            
                            {! item?.use_heading_link && item?.heading }
                          

                        </Row>
                        {renderListRows(props?.columns_number, item?.list_rows)}
                    </List>
                )
            } else {
                return renderListRows(props?.columns_number, item?.list_rows)
            }
        })

        return listItems
    }

	const renderList = rows => {
		return (
			<List>
				{renderListRows(props?.columns_number, rows)}
			</List>
		)
	}
        
	return (
		<Wrapper
            isPressPage={isPressPage}
			topBorder={headings.length > 0}
			bottomBorder={display_bottom_line}
		>
			{headings.length > 0 && (
				<ListHeadings>
					{renderListHeadings(headings)}
				</ListHeadings>
			)}
			{props.listing && renderListing(props.listing)}
			{props.rows && renderList(props.rows)}
		</Wrapper>
	)
}

const ListHeadings = styled.div``
const Row = styled.div``
const Col = styled.div``
const List = styled.div``

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
	width: 100%;

    ${ListHeadings} {
        display: flex;
		padding-bottom: 14px;
		border-bottom: 2px solid black;

        ${media.phone`
            padding-bottom: 0;

            // hide headings but keep border
            * {
                display: none;
            }

            // hide everything including border on press page

            ${props => {
                if (props.isPressPage)
                    return css`
                        display: none;
                    `
            }}
        `}

        &,
        * {
            ${type.heading2}
        }
    }

	${List} {
		padding: 23px 0 29px;

        ${media.phone`
            padding: 25px 0 27px;
        `}

		${props => {
			if (!props.topBorder)
				return css`
					padding-top: 0;
				`
		}}

		${props => {
			if (props.bottomBorder)
				return css`
					border-bottom: 2px solid black;
				`
		}}

		${props => {
			if (!props.bottomBorder)
				return css`
					padding-bottom: 0;

                    ${media.phone`
                        padding-bottom: 0;
                    `}
				`
		}}

        ${props => {
            if (props.isPressPage)
                return css`
                    ${media.phone`
                        padding-bottom: 0;
                        border-bottom: 0;
                    `}
                `
        }}
	}

    ${Row} {
        display: flex;

        ${media.phone`
            display: block;

            &:not(.first-row):not(.listing-heading) {
                margin-top: 25px;
            }
        `}

        &, * {
            font-size: 14px;
            line-height: 20px;

            ${media.phone`
                font-size: 16px;
                line-height: 1.21;
                letter-spacing: 0.1px;
            `}
        }

        &.listing-heading {
			padding-bottom: 20px;

            ${media.phone`
                display: none;
            `}
			
            &, * {
                ${type.heading2}
            }
        }
    }

    ${Col} {
        position: relative;
        flex: 1;
        padding-right: 32px;
        overflow: hidden;
    }
`

export default TableList
