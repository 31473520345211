import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, type, bgImage, bgIcon } from '../../../styles/global'

const BlockDoubleImage = (props) => {
	const { double_image } = props;
	if (!double_image.display) return false
	const imageLarge = double_image?.image_large
	const imageSmall = double_image?.image_small
        
	return (
		<Wrapper
			swapPositions={double_image?.swap_positions}
		>
			<Col>
				<ImageWrapper
					style={{
						background: !imageLarge && 'black'
					}}
				>
					<Image
						key={imageLarge?.sizes?.medium2}
						src={imageLarge?.sizes?.medium2}
					>
						{(src, loading) => {
							return (
								<BGImage 
									image={src} 
									style={{opacity: loading ? 0 : 1}}  
                                    landscape={imageLarge.height < imageLarge.width}
								/>
							)
						}}
					</Image>
				</ImageWrapper>
				{double_image.image_large_use_caption && double_image.image_large_caption && (
					<Caption
						dangerouslySetInnerHTML={{
							__html: double_image.image_large_caption,
						}}
					/>
				)}
			</Col>
			<Col>
				<ImageWrapper
					style={{
						background: !imageSmall && 'black'
					}}
				>
					<Image
						key={imageSmall?.sizes?.medium2}
						src={imageSmall?.sizes?.medium2}
					>
						{(src, loading) => {
							return (
								<BGImage 
									image={src} 
									style={{opacity: loading ? 0 : 1}}  
                                    landscape={imageSmall.height < imageSmall.width}
								/>
							)
						}}
					</Image> 
				</ImageWrapper>
				{double_image.image_small_use_caption && double_image.image_small_caption && (
					<Caption
						dangerouslySetInnerHTML={{
							__html: double_image.image_small_caption,
						}}
					/>
				)}
			</Col>
		</Wrapper>
	)
}

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
    ${props => {
        if(!props.landscape) return css`
            ${bgIcon}
        `
    }}
`

const ImageWrapper = styled.div``
const Caption = styled.div``
const Col = styled.div``

const Wrapper = styled.div`
	display: flex;
	margin: 0 -12px;

	${media.phone`
		display: block;
		margin: 0;
	`}

	${props => {
		if (props.swapPositions)
			return css`
				${Col}:last-child {
					order: -1;
				}
			`
	}}


	// columns shared styles
	${Col} {
		padding: 0 12px;

		${media.phone`
			margin-bottom: 56px; 
			padding: 0;

			${Caption} {
				margin-top: 11px!important;
			}
		`}
	}

	// large image
	${Col}:first-child {
		width: 62.75%;

		${media.phone`
			width: 100%;
		`}

		${Caption} {
			margin-top: 17px;
		}
	}

	// small image
	${Col}:last-child {
		width: 37.25%;

		${media.phone`
			width: 80.23%
		`}

		${Caption} {
			margin-top: 13px;
		}
	}

	${ImageWrapper} {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 70.14%;

		${Image}, ${BGImage} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}

	${Caption} {
		${type.heading2}

		${media.phone`
			font-size: 14px;
			line-height: 1.27;
			text-align: center;
		`}
	}
`


BlockDoubleImage.wrapper = css`
	margin-bottom: 109px;

	${media.phone`
		margin-bottom: 80px;
	`}
`

export default BlockDoubleImage
