import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockHeading = (props) => {
	const { content } = props
	if (!content.display || !content.heading) return false
        
	return (
        <Wrapper>
            <Heading
                dangerouslySetInnerHTML={{__html: content.heading}}  
            />
        </Wrapper>
	)
}

const Wrapper = styled.div``

const Heading = styled.div`
    margin-left: 175px;
    ${type.heading1}

    ${media.phone`
        margin-left: 20px;
    `}
`

BlockHeading.wrapper = css`
    margin-bottom: 65px;

    ${media.phone`
        margin-bottom: 32px;
    `}
`

export default BlockHeading
