import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, type, bgImage } from '../../../styles/global'

const BlockSingleImage = (props) => {
	const { single_image } = props;
	if (!single_image.display) return false
	const image = single_image?.image?.sizes?.medium2
        
	return (
		<Wrapper>
			<ImageWrapper
				style={{
					background: !image && 'black'
				}}
			>
				<Image
					key={image}
					src={image}
				>
					{(src, loading) => {
						return (
							<BGImage 
								image={src} 
								style={{opacity: loading ? 0 : 1}}  
							/>
						)
					}}
				</Image> 
			</ImageWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 854px;
	max-width: 100%;
	margin: 0 auto;
`

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const ImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 70.14%;

	${Image}, ${BGImage} {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
`


BlockSingleImage.wrapper = css`
	margin-bottom: 109px;

	${media.phone`
		margin-bottom: 88px;
	`}
`

export default BlockSingleImage
