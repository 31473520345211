import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { container, padding, type } from '../../styles/global'
import { media } from '../../styles/utils'

const PageTitle = (props) => {
    const { title } = props
	return (
		<Wrapper>
            <Container>
                <Heading>{title}</Heading>
                <Border />
            </Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
    text-align: center;
`

const Heading = styled.div`
    ${type.heading1}
    line-height: 1;
    padding-bottom: 39px;

    ${media.phone`
        padding-top: 41px;
        padding-bottom: 60px;
    `}
`

const Border = styled.div`
    width: 100%;
    height: 5px;
    background: black;
`

export default PageTitle
