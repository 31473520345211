import React, { useState, useEffect, useRef } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'
import { useMedia } from 'react-use'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'

import sliderArrow from '../../assets/icons/slider-arrow.svg'
import sliderArrowPrev from '../../assets/icons/slider-arrow-prev.svg'

const Slider = (props) => {
    const { slides, renderSlides, slideImageHeight } = props
    const [activeIndex, setActiveIndex] = useState(false)
    const swiperRef = useRef(null)
    const canLoop = slides && slides.length > 1
    const isPhone = useBreakpoint('tablet')

    const updateSlideIndex = swiper => {
        if (swiper) {
            if (swiper.activeIndex > slides.length) {
                setActiveIndex(1)
            } else if (swiper.activeIndex === 0) {
                setActiveIndex(slides.length)
            } else {
                setActiveIndex(swiper.activeIndex)
            }
        }
    }

    const swiperParams = {
        loop: canLoop,
        // effect: false,
        // speed: 0,
        noSwiping: isPhone ? false : true,
        on: {
            slideChange: updateSlideIndex
        }
    }

    const nextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const prevSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    const renderCounter = () => {
        if (!activeIndex) return
        const counterText = `${activeIndex}/${slides.length}`
        return (
            <Counter>
                {counterText}
            </Counter>
        )
    }

    return (
        <SliderWrapper
            slideImageHeight={slideImageHeight}
            sliderArrow={sliderArrow}
            style={{
                opacity: slideImageHeight > 0 ? 1 : 0
            }}
        >
            <PrevButton
                onClick={prevSlide}
            />
            <NextButton
                onClick={nextSlide}
            />
            <Swiper ref={swiperRef} {...swiperParams}>
                {renderSlides && renderSlides(slides)}
            </Swiper>
            {renderCounter()}
        </SliderWrapper>
    )
}

const NextButton = styled.div``
const PrevButton = styled.div``
const Counter = styled.div``

const SliderWrapper = styled.div`
    position: relative;
    width: 100%;
    transition: opacity 0.45s ease;
    margin-bottom: 103px;

    ${media.phone`
        margin-bottom: 64px;
    `}

    ${NextButton},
    ${PrevButton} {
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 50%;

        ${media.tablet`
            display: none;
        `}
    }

    ${PrevButton} {
        left: 0;
        cursor: url(${sliderArrowPrev}), auto;
    }
    ${NextButton} {
        right: 0;
        cursor: url(${sliderArrow}), auto;
    }

    /* custom swiper styles */
    ${Counter}, .swiper-container {
        width: 855px;
        max-width: 100%;
        margin: 0 auto;
    }

    ${Counter} {
        z-index: 9;
        position: absolute;
        left: 50%;
        top: ${props => props.slideImageHeight}px;
        transform: translate(-50%, 22px);
        width: 855px;
        max-width: 100%;
        margin: 0 auto;

        ${media.phone`
            transform: translate(-50%, 12px);
        `}

        &, * {
            ${type.heading2}
            line-height: 1;

            ${media.phone`
                font-size: 14px;
                line-height: 22px;
            `}
        }
    }
`

export default Slider
