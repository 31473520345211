export const menu_links = [
    {
        title: 'Works',
        link: '/'
    },
    {
        title: 'About',
        link: '/about'
    },
    {
        title: 'Press & Citations',
        link: '/press-citations'
    },
    {
        title: 'David Pledger',
        link: '/david-pledger'
    },
]