import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach } from 'lodash'
import ProgressiveImage from 'react-progressive-image'
import { useMouse, useMeasure } from 'react-use'

import { Ticker } from '../'
import { container, padding, bgImage, type } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'

const GridItemThumb = ({item}) => {
	const wrapperRef = React.useRef(null)
	const { elX, elY } = useMouse(wrapperRef)
    const [captionRef, { width: captionWidth }] = useMeasure()
	const [showCaption, setShowCaption] = useState(false)
	const [animateCaption, setAnimateCaption] = useState(false)
	const isTablet = useBreakpoint('tablet')

	const image = item?.hero_image?.sizes?.large

	let years = item?.start_year
	if (item.other_years) {
		if (item.other_years.length >= 2) {
			years = years + `&nbsp;-&nbsp;<br>${item.other_years[item.other_years.length-1].year}`
		} else {
			forEach(item.other_years, (item, key) => {
				//years = years + `,&nbsp;<br>${item.year}`
                years = years + `&nbsp;-&nbsp;<br>${item.year}`
			})
		}
	}

	let locations = false
	if (item?.locations?.length) {
		forEach(item.locations, (item, key) => {
			if (
				item.city &&
				locations &&
				!locations.includes(item.city)
			) {
				locations += `, ${item.city}`
			} else {
				locations = `${item.city}`
			}
		})
	}

	const toggleCaption = () => {
		if (!showCaption) {
			if (locations.length > 0) {
				setShowCaption(true)
			}
			if (captionWidth > 200) {
				setAnimateCaption(true)
			}
		} else {
			setShowCaption(false)
			setAnimateCaption(false)
		}
	}

	return (
		<Item
			ref={wrapperRef}
			as={Link}
			to={`/projects/${item.slug}`}
		>
			<ImageWrapper
				onMouseEnter={() => toggleCaption()}
				onMouseLeave={() => toggleCaption()}
				style={{
					backgroundColor: !image && 'black',
					cursor: locations.length > 0 ? 'none' : 'pointer'
				}}
			>
				{image && (
					<Image key={image} src={image}>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>
				)}
				{locations.length > 0 && !isTablet && (
					<CaptionWrapper
						style={{
							left: showCaption && elX && elX,
							top: showCaption && elY && elY,
							opacity: showCaption ? 1 : 0,
							width: animateCaption ? '200px' : 'initial',
							transform: animateCaption ? 'translate(-50%, -75%)' : 'translate(-50%, -50%)',
						}}
					>
						<Caption
							ref={captionRef}
						>
							<Text
								style={{
									opacity: showCaption && !animateCaption ? 1 : 0
								}}
								dangerouslySetInnerHTML={{
									__html: locations,
								}}
							/>
							{captionWidth > 200 && (
								<Ticker
									animate={animateCaption}
									tickerWidth={200}
									textWidth={captionWidth}
									text={locations}
								/>
							)}
						</Caption>
					</CaptionWrapper>
				)}
			</ImageWrapper>
			<Info>
				<Years
					dangerouslySetInnerHTML={{
						__html: years,
					}}
				/>
				<Subheading>{item.title}</Subheading>
			</Info>
		</Item>
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

const ImageWrapper = styled.div``
const Years = styled.div``

const Item = styled.div`
    position: relative;
    width: 25%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 75px;
	overflow: hidden;

	${media.phone`
		width: 50%;
		margin-bottom: 25px;
	`}

    ${ImageWrapper} {
        position: relative;
        display: flex;
        width: 100%;
        height: 0;
        padding-top: 74.25%;
        overflow: hidden;

        ${BGImage} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    ${Info} {
        display: flex;
        margin-top: 15px;

		${media.phone`
			flex-direction: column;
		`}

        &,
        * {
            ${type.heading3}
        }

        ${Years} {
            margin-right: 24px;

			${media.phone`
				margin-right: 0;

				br {
					display: none;
				}
			`}
        }

		${Subheading} {
			${media.phone`
				margin-top: 2px;
			`}
		}
    }
`

// Caption

const Text = styled.div``
const Caption = styled.div``
const CaptionWrapper = styled.div`
	position: absolute;
    z-index: 1;
    pointer-events: none;
    user-select: none;

	${Caption} {
		display: inline-block;
		overflow: hidden;
	}

	${media.phone`
		display: none!important;
	`}
	
	${Text} {
		white-space: nowrap;
	}

	&, * {
		${type.heading2}
		line-height: 1.1;
		color: white;
	}
`

export default GridItemThumb