import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { TableList } from '../../../components'
import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockTableList = (props) => {
	const { table_list } = props
	if (!table_list.display) return false
        
	return (
		<Wrapper>
			{table_list.heading && (
				<Heading>{table_list.heading}</Heading>
			)}
            <TableList
				{...table_list}
			/>
		</Wrapper>
	)
}

const Heading = styled.div``

const Wrapper = styled.div`
	> ${Heading} {
		margin-bottom: 29px;
		margin-left: 175px;
		${type.heading1}

		${media.phone`
			margin-left: 20px;
		`}
	}
`

BlockTableList.wrapper = css`
	margin-bottom: 60px;
`

export default BlockTableList
