import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

import pdfDownload from '../../../assets/icons/pdf-download.svg'

const BlockFileDownload = (props) => {
	const { file_download } = props;
	if (!file_download.display || !file_download.file) return false
        
	return (
		<Wrapper>
			<Description
				dangerouslySetInnerHTML={{__html: file_download?.description}}  
			/>
            <DownloadLink
                href={file_download.file}
                target={'_blank'}
            >
                <Icon
                    src={pdfDownload}
                />
                <Label
                    dangerouslySetInnerHTML={{__html: file_download?.file_descriptor}}  
                />
            </DownloadLink>
		</Wrapper>
	)
}

const Description = styled.div``
const DownloadLink = styled.a``
const Icon = styled.img``
const Label = styled.div``

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    ${Description}, ${Label} { 
        &, * {
            ${type.body}
        }
    }

    ${Description} {
        margin-right: 24px;

        ${media.phone`
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        `}
    }

    ${DownloadLink} {
        display: flex;
        align-items: center;

        ${Icon} {
            margin-right: 16px;
        }

        ${Label} {

        }
    }
`

BlockFileDownload.wrapper = css`
	margin-bottom: 57px;
`

export default BlockFileDownload
