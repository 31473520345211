import React, { useState, useEffect, useContext, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useMeasure } from 'react-use'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'
import { getGlobalData } from '../../utils'

import logo from '../../assets/logo/nyid-logo.svg'

import { AcknowledgementContext } from './AcknowledgementProvider'

const Acknowledgement = (props) => {
    const { global: globalData } = getGlobalData()
    const acknowledgement = useContext(AcknowledgementContext)
    const {x, y} = useWindowScroll();
    const [ref, { height }] = useMeasure();

    useEffect(() => {
        if (height > 0) {
            if (y >= height && !acknowledgement.scrolledPast) {
                acknowledgement.setScrolledPast(true)
            } else if (y < height && acknowledgement.scrolledPast) {
                acknowledgement.setScrolledPast(false)
            }
        }
    }, [y])

    return (
        <Wrapper
            seen={acknowledgement.seen}
            ref={ref}
        >
            <Container>
                <Logo
                    onClick={() => acknowledgement.setScroll(true)}
                >
                    <Icon src={logo} />
                </Logo>

                <Text>
                    <Dot />
                    {globalData?.acknowledgement}
                </Text>
            </Container>
        </Wrapper>
    )
}

// Shared

const Logo = styled.div``
const Icon = styled.img``
const Text = styled.div``
const Dot = styled.div``

// Layout

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;

    ${props => {
        if (props.seen)
            return css`
                pointer-events: none;
            `
    }}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${container};
    ${padding};
    padding-top: 33px;
    padding-bottom: 33px;

    ${Logo} {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 209px;
        height: 49px;
        margin-top: -24px;
        margin-left: -104px;
        cursor: pointer;
    }

    ${Text} {
        margin-top: auto;

        ${Dot} {
            display: inline-block;
            width: 19px;
            height: 19px;
            margin-right: 8px;
            border-radius: 50%;
            background: white;

            ${media.phone`
                width: 13px;
                height: 13px;
            `}
        }

        &,
        * {
            ${type.heading2}
            color: white;
        }
    }
`

export default Acknowledgement
