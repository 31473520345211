import React, { useRef, useState, useEffect, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { MailingList } from '../../components'
import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { MenuContext } from './MenuProvider'
import { menu_links } from './data'

const Menu = (props) => {
    const menuRef = useRef()
    const menu = useContext(MenuContext)
    const menuLinks = menu_links

    useEffect(() => {
        if (menu.active && menuRef.current) {
            disableBodyScroll(menuRef.current)
        } else {
            clearAllBodyScrollLocks()
        }
    }, [menu.active])

    const variants = {
        hidden: {
            x: '100%',
        },
        visible: {
            x: '0%',
        },
    }

    const renderMenuLinks = () => {
        const items = menuLinks.map((item, i) => {
            return (
                <MenuLink
                    key={i}
                    as={Link}
                    to={item.link}
                    onClick={() => menu.toggleActive()}
                    activeClassName={'active'}
                >
                    <Dot />
                    {item.title}
                </MenuLink>
            )
        })

        return (
            <MenuLinks>
                {items}
            </MenuLinks>
        )
    }

    return (
        <>
            <BGOverlay
                active={menu.active}
                onClick={() => menu.toggleActive()}
            />
            <Wrapper
                id={'menu-wrapper'}
                ref={menuRef}
                active={menu.active}
                initial={'hidden'}
                animate={menu.active ? 'visible' : 'hidden'}
                variants={variants}
                transition={{ type: 'spring', damping: 60, stiffness: 180 }}
            >
                <Container>
                    <Top>
                        <MenuToggle onClick={() => menu.toggleActive()}>
                            Close
                        </MenuToggle>
                    </Top>
                    {renderMenuLinks()}
                    <MailingListWrapper>
                        <MailingList />
                    </MailingListWrapper>
                </Container>
            </Wrapper>
        </>
    )
}

// Layout

const Wrapper = styled(motion.div)`
    z-index: 20;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 647px;
    max-width: 100%;
    height: 100%;
    padding: 35px 53px 0;
    background-color: #a6a6a6;
    pointer-events: none;
    overflow-y: auto;

    ${(props) => {
        if (props.active)
            return css`
                pointer-events: all;
            `
    }}

    ${media.tablet`
        width: 500px;
    `}

    ${media.phone`
        padding: 31px 25px 0;
    `}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${container}
`

const MenuToggle = styled.div``
const Top = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    ${MenuToggle} {
        ${type.heading1}
        line-height: 1;
        cursor: pointer;
    }
`

const MenuLink = styled.div``
const Dot = styled.div``
const MenuLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 108px;
    margin-bottom: 64px;
    padding-top: 18px;
    border-top: 5px solid black;

    ${Dot} {
        display: none;
        width: 27px;
        height: 27px;
        margin-right: 14px;
        border-radius: 50%;
        background: black;

        ${media.tablet`
            display: none!important;
        `}
    }

    ${MenuLink} {
        display: flex;
        align-items: center;
        font-size: 35px;
        line-height: 1.29;

        &:hover ${Dot} {
            display: flex;
        }
    }
`

const MailingListWrapper = styled.div`
    margin-top: auto;
    padding-bottom: 106px;

    ${media.tablet`
        padding-bottom: 64px;
    `}
`

const BGOverlay = styled.div`
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    ${(props) => {
        if (props.active)
            return css`
                pointer-events: all;
            `
    }}
`

export default Menu
