import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach } from 'lodash'
import ProgressiveImage from 'react-progressive-image'
import { useMouse } from 'react-use'

import { Ticker } from '../'
import { container, padding, bgImage, type } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'

const GridItemList = ({ item }) => {
    const mouseRef = React.useRef(null)
    const { elX, elY } = useMouse(mouseRef)
    const [showImage, setShowImage] = useState(false)
    const isTablet = useBreakpoint('tablet')

    const image = item?.hero_image?.sizes?.medium
    let years = item?.start_year
    if (item.other_years) {
        if (item.other_years) {
            if (item.other_years.length >= 2) {
                years = years + ` - ${item.other_years[item.other_years.length-1].year}`
            } else {
                forEach(item.other_years, (item, key) => {
                    years = years + `, ${item.year}`
                })
            }
        }
    }
    let locationString
    if (item?.locations?.length) {
        const firstLocation = item.locations[0]
        locationString = `${firstLocation.location}`
        if (firstLocation.city) {
            if(locationString!='') locationString += ', '
            locationString += `${firstLocation.city}`
        }
    }

    const toggleImage = () => {
        setShowImage(!showImage)
    }

    return (
        <Item
            as={Link}
            to={`/projects/${item.slug}`}
            onMouseEnter={() => !isTablet && toggleImage()}
            onMouseLeave={() => !isTablet && toggleImage()}
        >
            <Col ref={mouseRef} className={'list-column'}>
                <Ticker
                    className='heading'
                    text={item.title}
                />
            </Col>
            <Col className={'list-column'}>
                <Ticker
                    className='years'
                    text={years}
                />
            </Col>
            <Col className={'list-column'}>
                {locationString && (
                    <Ticker
                        className='location'
                        text={locationString}
                    />
                )}
            </Col>
            <Col className={'list-column'}>
                <Ticker
                    className='format'
                    text={item.format}
                />
            </Col>
            <ImageWrapper
                style={{
                    backgroundColor: !image && 'black',
                    left: elX && elX,
                    opacity: showImage ? 1 : 0
                }}
            >
                {image && (
                    <Image key={image} src={image}>
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
            </ImageWrapper>
        </Item>
    )
}

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const ImageWrapper = styled.div``
const Heading = styled.div``
const Years = styled.div``
const Location = styled.div``
const Format = styled.div``

const Col = styled.div`
    padding-right: 32px;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;

    ${media.phone`
        white-space: initial;
        text-overflow: initial;
        overflow-x: initial;
        padding-right: 0;
    `}
`

const Item = styled.div`
	position: relative;
    display: flex;
    width: 100%;

    ${media.phone`
        display: block;
        margin-top: 22px;
    `}

	&, * {
		font-size: 14px;
		line-height: 20px;
	}

    ${ImageWrapper} {
		z-index: 2;
        position: absolute;
		top: 100%;
		left: 0;
        pointer-events: none;
        user-select: none;
        width: 260px;
        height: 174px;
        overflow: hidden;

        ${Image}, ${BGImage} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }
`

export default GridItemList
