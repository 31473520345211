import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, padding } from '../../styles/global'
import { resolveBlock } from './utils'

const Block = (props) => {
    const { layout, index, lastBlock } = props
    const BlockLayout = resolveBlock(layout)
    // console.log(layout)

    if (!BlockLayout) return <></>

    return (
        <Wrapper
            className={`block_${layout}`}
            layout={layout}
            blockProps={props}
            styles={BlockLayout && BlockLayout.wrapper}
            lastBlock={lastBlock}
        >
            {BlockLayout && <BlockLayout {...props} />}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
	
	/* Block specific container styles */

	${(props) => {
        if (props.styles) return props.styles
    }}
`

export default Block
