import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import { Link } from '../../../components'
import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockInfoBox = (props) => {
	const { info_box } = props;
	if (!info_box.display) return false

	const renderText = () => {
		return (
			<>
				{info_box.heading && (
					<Heading
						dangerouslySetInnerHTML={{
							__html: info_box.heading,
						}}
					/>
				)}
				{info_box.short_description && (
					<Description
						dangerouslySetInnerHTML={{
							__html: info_box.short_description,
						}}
					/>
				)}
				{info_box.use_link && info_box.link_text && (
					<Info
						dangerouslySetInnerHTML={{
							__html: info_box.link_text,
						}}
					/>
				)}
			</>
		)
	}
        
	return (
		<Wrapper>
			{info_box.use_link && info_box.link && (
				<Link
					className={'custom-link'}
					to={info_box?.link?.url}
				>
					{renderText()}
				</Link>
			)}
		</Wrapper>
	)
}

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Wrapper = styled.div`
	width: 679px;
	max-width: 100%;
	margin: 0 auto;

	&, * {
		color: white;
		text-align: center;
	}

	.custom-link {
		display: block;
		width: 100%;
		padding: 50px 53px 58px;
		background-color: black;

		${media.phone`
			padding: 50px 26px 45px;
		`}
	}

	${Heading} {
		&, * {
			${type.heading1}
		}
	}

	${Description} {
		margin-top: 49px;
		padding-top: 36px;
		border-top: 3px solid white;

		${media.phone`
			margin-top: 46px;
			padding-top: 45px;
		`}
	}

	${Info} {
		margin-top: 56px;

		${media.phone`
			margin-top: 40px;
		`}
	}
`

BlockInfoBox.wrapper = css`
	margin-bottom: 220px;

	${media.phone`
		margin-bottom: 205px;
	`}
`

export default BlockInfoBox
