import React, { useContext, useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Transition from '../../transition';
import { motion } from 'framer-motion'
import { useMount, useLocation } from 'react-use'

import { MetaTags, Header, Footer, Menu, Acknowledgement } from '../'
import { media } from '../../styles/utils';
import { headerHeight, phoneHeaderHeight } from '../Header/Header'
import { AcknowledgementContext } from '../Acknowledgement/AcknowledgementProvider'

const Layout = ({ children, meta, bgColour, isHome }) => {
	const acknowledgement = useContext(AcknowledgementContext)
	const [isHomeLocation, setIsHomeLocation] = useState(false)
	const [acknowledgementActive, setAcknowledgementActive] = useState(acknowledgement.active)
	const [acknowledgementScrolledPast, setAcknowledgementScrolledPast] = useState(false)
	const [animate, setAnimate] = useState(false)
	const location = useLocation()

	useEffect(() => {
		if (location.pathname==='/') {
			// is home
			setIsHomeLocation(true)
		}
		if (location.pathname !== '/' && acknowledgementActive && isHomeLocation) {
			// was home
			setIsHomeLocation(false)
			acknowledgement.setActive(false)
		} 
	}, [location])

	useEffect(() => {
		setAcknowledgementActive(acknowledgement.active)
	}, [acknowledgement.active])

	useEffect(() => {
		if (acknowledgement.scroll && isHome) {
			setAnimate(true)
		}
	}, [acknowledgement.scroll])

	useEffect(() => {
		setAcknowledgementScrolledPast(acknowledgement.scrolledPast)
	}, [acknowledgement.scrolledPast])

    const acknowledgementVariants = {
		hide: {
            marginTop: '0vh',
        },
    }

	const onAnimationComplete = () => {
		acknowledgement.setScrolledPast(true)
		acknowledgement.setActive(false)
	}

	return (
		<>
			<MetaTags
				{...meta}
			/>

			{isHome && acknowledgement.active && (
				<Acknowledgement />
			)}

			<OuterWrapper
				id={'outer-wrapper'}
				animate={animate && 'hide'}
				variants={acknowledgementVariants}
				transition={{ duration: 0.8 }}
				acknowledgementActive={acknowledgementActive && isHome}
				onAnimationComplete={onAnimationComplete}
			>
				<Header bgColour={bgColour} fixed={!isHome || !acknowledgementActive || acknowledgementScrolledPast} />
				<Menu />

				<Wrapper
					bgColour={bgColour}

				>
					<Transition>
						<>
							{children}
							<Footer bgColour={bgColour} visible={!isHome || !acknowledgementActive || acknowledgementScrolledPast} />
						</>
					</Transition>
				</Wrapper>
			</OuterWrapper>
		</>
	)
}


const OuterWrapper = styled(motion.div)`
	position: relative;
	z-index: 1;
	background: white;
	width: 100%;
	overflow: hidden;

	${props => {
		if (props.acknowledgementActive)
			return css`
				margin-top: 100vh;
			`
	}}
`

const Wrapper = styled.div`
	#transition-wrapper {
		width: 100%;
		padding-top: ${headerHeight}px;
		box-sizing: border-box;
		min-height: 100vh;

		${props => {
			if (props.bgColour)
				return css`
					background: ${props.bgColour};
				`
		}}

		${media.phone`
			padding-top: ${phoneHeaderHeight}px;
			padding-bottom: 0;
		`}
	}
`

export default Layout
