import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockSectionDivider = (props) => {
	const { section_divider } = props;
	if (!section_divider.display) return false
        
	return (
		<Wrapper>
			<Divider />
		</Wrapper>
	)
}

const Wrapper = styled.div``

const Divider = styled.div`
    width: 100%;
    height: 3px;
    background: black;
`

BlockSectionDivider.wrapper = css`
	margin-bottom: 48px;
`

export default BlockSectionDivider
