import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockBodyText = (props) => {
	const { body_text } = props;
	if (!body_text.display || !body_text.text) return false
        
	return (
		<Wrapper>
			<Text
				dangerouslySetInnerHTML={{__html: body_text.text}}  
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 50%;

    ${media.phone`
        width: 100%;
    `}
`

const Text = styled.div`
	&, * {
		${type.body}
	}

	> *:not(:first-child) {
		margin-top: 1em;
	}
`

BlockBodyText.wrapper = css`
	margin-bottom: 136px;
`

export default BlockBodyText
