import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, type, bgImage } from '../../../styles/global'

const BlockPeopleGrid = (props) => {
	const { people_grid } = props;
	if (!people_grid.display || !people_grid.grid) return false

	const items = people_grid.grid.map((item, i) => {
		return (
			<Item key={i}>
				<ImageWrapper>
					<ImageSizer>
						{item.image && (
							<Image
								key={item?.image?.id}
								src={item?.image?.sizes?.medium2}
							>
								{(src, loading) => {
									return (
										<BGImage 
											image={src} 
											style={{opacity: loading ? 0 : 1}}  
										/>
									)
								}}
							</Image>
						)}
					</ImageSizer>
				</ImageWrapper>
				{item.name && (
					<Subheading>
						{item.name}
					</Subheading>
				)}
				{item.description && (
					<Description
						dangerouslySetInnerHTML={{
							__html: item.description,
						}}
					/>
				)}
			</Item>
		)
	})
        
	return (
		<Wrapper>
            <Heading>People</Heading>
			<Items>
				{items}
			</Items>
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	padding-top: 62px;
	padding-bottom: 60px;
	border-top: 3px solid black;
	border-bottom: 3px solid black;

	${media.phone`
		padding-top: 92px;
		padding-bottom: 57px;
	`}

	> ${Heading} {
		margin-left: 175px;
    	${type.heading1}

		${media.phone`
			margin-left: 20px;
		`}
	}
`

const ImageWrapper = styled.div``
const ImageSizer = styled.div``
const Item = styled.div``

const Items = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: 100px -13px -97px;

	${media.phone`
		margin: 70px 0 -96px;
	`}

	${Item} {
		width: 50%;
		margin-bottom: 97px;
		padding: 0 13px;

		${media.phone`
			width: 100%;
			margin-bottom: 96px;
			padding: 0;
		`}
	}

	${ImageWrapper} {
		width: 262px;
		max-width: 100%;
		margin: 0 auto;
	}

	${ImageSizer} {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 73.67%;

		${Image}, ${BGImage} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%:
		}
	}

	${Subheading} {
		margin: 48px 0;
		text-align: center;

		${media.phone`
			margin: 30px 0 19px;
		`}

		&, * {
			${type.heading2}
			line-height: 1;
		}
	}

	${Description} {
		&, * {
			${type.heading2}
		}
	}
`

BlockPeopleGrid.wrapper = css`
	margin-bottom: 103px;

	${media.phone`
		margin-bottom: 70px;
	`}
`

export default BlockPeopleGrid
